"use client";
import { Notification } from "@mantine/core";
import Image from "next/image";
import { useRouter } from "next/navigation";

import {
  ArrowForwardIcon,
  Button,
  CheckIcon,
  List,
  Flex,
  Center,
  Group,
  Stack,
  Container,
  H5,
  H2,
  Text,
  StarIcon,
} from "@/components/ui/elements";

import classes from "./_css/Home.module.css";

type Props = {
  authToken: string | null;
};

export function Home({ authToken }: Props) {
  const router = useRouter();
  const getEndOfMonth = (addMonth: number) => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + addMonth, 0);
    const year = lastDay.getFullYear();
    const month = lastDay.getMonth() + 1;
    const day = lastDay.getDate();
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][
      lastDay.getDay()
    ];

    return `${year}年${month}月${day}日(${dayOfWeek})`;
  };
  const handleClick = () => {
    if (authToken) {
      router.push("/work");
    } else {
      router.push("/signup");
    }
  };
  return (
    <>
      {/* <Header /> */}
      <Container className={classes.container}>
        <Flex className={classes.wrapper}>
          <Center maw={"100%"}>
            <Group className={classes.inner}>
              <Group>
                <Stack gap={0}>
                  <H5>あなたにぴったりの求人がすぐ見つかる</H5>
                  <Stack gap={"xs"}>
                    <H2>【介護／福祉特化型】単発バイトアプリ</H2>
                    <Group justify="center">
                      <Image
                        src={"/images/icon/care_link_logo_horizonal.png"}
                        width={311}
                        height={74}
                        alt=""
                      />
                    </Group>
                  </Stack>
                </Stack>
                <Stack>
                  <H5>３つのメリット</H5>
                  <List spacing="sm" size="sm" icon={<CheckIcon size={12} />}>
                    <List.Item>
                      <b>今すぐ始められる</b>
                      <br />– 面接・履歴書不要！アプリで完結！
                    </List.Item>
                    <List.Item>
                      <b>好きな時だけ働ける</b>
                      <br />– 半日勤務OK！介護の単発求人がすぐ見つかる。
                    </List.Item>
                    <List.Item>
                      <b>お金がすぐにもらえる</b>
                      <br />– 働いた当日に報酬がもらえる。
                    </List.Item>
                  </List>
                  <Text>
                    ＊現在は東京都・千葉県・埼玉県・神奈川県の求人が掲載中です。
                  </Text>

                  <Group justify="right">
                    <Notification
                      withCloseButton={false}
                      radius="lg"
                      color="yellow"
                      icon={<StarIcon />}
                      withBorder
                      title="初回勤務完了でAmazonギフト券5,000円分プレゼント"
                    >
                      ＊期限：{getEndOfMonth(3)}23:00まで
                    </Notification>
                    <Button
                      onClick={handleClick}
                      radius="xl"
                      size="md"
                      rightSection={<ArrowForwardIcon color="white" />}
                      data-testid="link-to-verification"
                    >
                      アカウント登録またはログイン
                    </Button>
                  </Group>
                </Stack>
              </Group>
              <div>
                <Image
                  src={"/images/hero/pixta_92487044_S.jpg"}
                  width={640}
                  height={452}
                  className={classes.image}
                  alt="ケアリンクイメージ"
                  sizes="(max-width: 40em) 180px 180px"
                />
              </div>
            </Group>
          </Center>
        </Flex>
        {/* <HomeFooter /> */}
      </Container>
    </>
  );
}
